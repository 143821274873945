import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdx-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`TiendApp`}</h1>
    <p>{`TiendApp was a startup that I co-founded in 2015. Our application was a SaaS platform where you could create your own online store and sell your products. It was inspired by Shopify but focused on the LatAm ecosystem. The application was built with Ruby on Rails and Angular JS. We were a team of 3 people (two colleagues and me). We all coded and we were responsible for the whole application.`}</p>
    <p>{`We had a lot of fun and we learned a lot. Unfortunately though, we focused too much on the product and we didn't have enough time to focus on the business. We managed to get a few customers and they were really happy with the platform, but the cost of maintaining each customer was high and our service fee was not enough to cover the costs. Additionally, we all were finishing our studies or working full-time, so we didn't have enough time to focus on the business. We decided to close the company in 2020.`}</p>
    <h2>{`Lessons learned`}</h2>
    <h3>{`Shiny toy syndrome`}</h3>
    <p>{`We invested too much time creating the perfect app, with the perfect code, with too many features. We should have focused on customer acquisition first and then build the features that our customers needed. Another thing that we should have attempted is to, instead of creating a platform from the ground up, focus on what existing platforms lack for our target users and build extensions to those platforms. That would have allowed us to have a much faster time to market and a much lower cost of development.`}</p>
    <p>{`Related to this, we should have invested less time perfecting the code and be coreageous enough to release half-baked features and finding product-market fit before perfecting them.`}</p>
    <h3>{`Raising money`}</h3>
    <p>{`In hindsight, we should have put more efforts in raising money from investors. We had a good product and we had a few customers. We managed to go from 0 to 1, but didn't double down in going from 1 to 100.`}</p>
    <h3>{`Valuing our time`}</h3>
    <p>{`We charged a small monthly fee, which didn't cover our costs at all but made us competitive considering other platforms. However, our customers requested a lot from us, like design changes to their stores, specific features, etc. Eventually, all of these requests ended up taking most of our time. It's a difficult balance, because there was a lot of competition and it was hard to acquire customers, but we should have taken measures to allow us to build a single solution for all of our customers instead of maintaining different designs or features for each customer.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      